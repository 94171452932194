@if (isLoading) {
  <!-- fakeLoading -->
  <div id="fakeLoading">
    <!-- loading -->
    <div class="loading">
      <!-- Logo -->
      <div class="Logo">
        <logo-ind-dark />
      </div>
      <div class="img">
        <svg style="margin: auto; background: none; display: block; shape-rendering: auto;" width="10" height="10"
          viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <circle cx="50" cy="50" r="32" stroke-width="8" stroke="#ffffff"
            stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1"
            values="0 50 50;360 50 50"></animateTransform>
          </circle>
        </svg>
        <!-- <img width="10" height="10" src="./assets/images/loading.png" /> -->
      </div>
    </div>
  </div>
}
