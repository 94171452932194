<div id="breadcrumb" class="page_{{RtPath}}" [ngClass]="{
    'hide': pageType == 'home',
    'show_page': pageType == 'page',
    'show_landingPage': pageType == 'landingPage',
    'desktopMode': deviceInfo == 'desktop',
    'tabletMode': deviceInfo == 'tablet',
    'mobileMode': deviceInfo == 'mobile'
  }" #breadCurrent>

  <div class="mainStr">

    @if (breadcrumbs.length) {
      <ul>
        <!-- <ng-container *ngIf="getLang == 'en' else langTW"> -->
        <!-- <li class="itm">
        <a aria-label='Home' routerLinkActive='active' [routerLink]='[langData.langEN.code]'>
          <span>Home</span>
        </a>
      </li> -->
    <!-- </ng-container> -->
    <!-- <ng-template #langTW> -->
    <!-- <li class="itm">
    <a aria-label='Home' routerLinkActive='active' [routerLink]='[langData.langTW.code]'>
      <span>首頁</span>
    </a>
  </li> -->
<!-- </ng-template> -->
@if ((showSpinnerAction$ | async)) {
  <li>&nbsp;</li>
} @else {
  @for (breadcrumb of breadcrumbs.slice(0,breadcrumbs.length-1); track breadcrumb; let i = $index) {
    <li class="itm">
      @if (breadcrumb.label === null;) {
      }
      @if (breadcrumb.label === " ") {
        <a routerLinkActive='active' [routerLink]='[""]'>&nbsp;</a>
      } @else {
        <a [routerLink]="[breadcrumb.url]" [queryParams]="breadcrumb.params">
          {{ breadcrumb.label }}
        </a>
      }
    </li>
  }
  <li class="itm active" aria-current="page">
    @if ((pageType$ | async) == 'detail') {
      {{detailPageName$ | async}}
    } @else {
      {{ breadcrumbs[breadcrumbs.length-1].label }}
    }
  </li>
}
</ul>
} @else {
  <ul class="hide">
    <li class="itm">&nbsp;</li>
    <li class="itm active">&nbsp;</li>
    @if ((showSpinnerAction$ | async)) {
      <li>&nbsp;</li>
    }
  </ul>
}


</div>




</div>


<!-- <li class="itm">
<button aria-label="Navigate to previous page" (click)='backClicked()' class='btn btn-sm btn-info'><span
class='icon-left-big'></span>Back</button>
</li> -->

<!-- <li class="breadcrumb-controls"><span class="icon-resize-full" (click)='goFullScreen()'></span></li> -->
